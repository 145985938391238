import { Auth } from 'aws-amplify'
import LogRocket from 'logrocket'
import { customAlphabet } from 'nanoid'
import md5 from 'md5'
import React from 'react'
import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import ExternalLink from '../../components/common/ExternalLink'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import {
  buildPath,
  getEndpointOfNode,
  updateAnalyticsEndpoint,
} from '../modules/utils'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as assetActions from './asset'
import * as endpointActions from './endpoint'
import * as folderActions from './folder'
import * as modalActions from './modal'
import * as permissionsActions from './permissions'
import * as settingsActions from './settings'
import * as invitationActions from './invitation'

//Folder action types
export const SET_TOKEN = 'SET_TOKEN'
export const LOGIN = 'LOGIN'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'
export const REGISTER = 'REGISTER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE'
export const SET_REGISTRATION_PENDING = 'SET_REGISTRATION_PENDING'
export const RESEND_CONFIRM = 'RESEND_CONFIRM'
export const RESEND_CONFIRM_SUCCESS = 'RESEND_CONFIRM_SUCCESS'
export const RESEND_CONFIRM_FAILURE = 'RESEND_CONFIRM_FAILURE'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const CLEAR_FORGOT_PASSWORD_MESSAGE = 'CLEAR_FORGOT_PASSWORD_MESSAGE'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const RESTORE_SESSION = 'RESTORE_SESSION'
export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST'
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS'
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST'
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS'
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE'
export const UPDATE_USER_PREFS = 'UPDATE_PREFS'
export const CANCEL_USER_UPDATE = 'CANCEL_USER_UPDATE'
export const SET_SIGNUP_ERROR = 'SET_SIGNUP_ERROR'
export const UPDATE_USER_FAILURE = 'UPDATE_PREFS_FAILURE'
export const SESSION_TIMEOUT_ACTION = 'SESSION_TIMEOUT_ACTION'
export const SET_PROFILE_PREVIEW = 'SET_PROFILE_PREVIEW'
export const ADMIN_CREATE_USER = 'ADMIN_CREATE_USER'
export const ADMIN_CREATE_USER_ERROR = 'ADMIN_CREATE_USER_ERROR'
export const ADMIN_CREATE_USER_SUCCESS = 'ADMIN_CREATE_USER_SUCCESS'
export const IMPORT_USERS = 'IMPORT_USERS'

export const setToken = (token) => ({
  type: SET_TOKEN,
  token,
})
/*
 * Action creator to kick off the login saga
 */
export const loginUser = (creds, refresh = false) => ({
  type: LOGIN,
  creds,
  refresh,
})

/*
 * Watcher function to initiate loginUserSaga
 */
export function* watchLoginUser() {
  yield takeLatest(LOGIN, loginUserSaga)
}

/*
 * loginUserSaga - attempt to login to AWS cognito with supplied credentials
 *   yields REQUEST to notify client async operation is in progress
 *   yields calls to getUserData, getFolders andgetAssets on successful login
 *   Yields PUT SUCCESS if data retrieval succeeds.  Saves user ID to local storage and sets
 *      loggedIn flag to true
 *   yields FAILURE in case of login error.  Possible errors include:
 *       UserNotConfirmedException - user attemps to login before clicking the link to confirm
 *          account. In this case we set the registrationPending flag
 *       UserNotFoundException, InvalidPasswordException, NotAuthorizedException - in these cases,
 *          pass the AWS error message on to the client.
 *       All other exceptions result in an "unknown error" message returned to the client
 */
export function* loginUserSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })

  try {
    const config = yield select((state) => state.config.appConfig)

    // sign in with Amplify auth
    const result = yield call(
      [Auth, Auth.signIn],
      action.creds.Username,
      action.creds.Password,
      { tenant: window.location.hostname }
    )

    LogRocket.identify((result && result.username) || action.user, {
      stage: config.stage,
    })

    // retrieve user data from DB
    const user = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/${encodeURI(
        action.creds.Username
      )}?tenant=${window.location.hostname}`,
      init: { method: 'GET' },
    })
    const version = yield select((state) => state.appState.version)
    yield call(updateAnalyticsEndpoint, user, version, config)
    yield put({ type: GET_USER_DATA_SUCCESS, user })

    // retrieve Folders and Assets associated with this user
    if (!action.refresh) {
      const data = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/zipstate/current?tenant=${window.location.hostname}`,
        headers: {
          Accept: 'application/json',
          'Accept-Encoding': 'gzip',
        },
        init: {
          method: 'POST',
          body: JSON.stringify(user.email),
        },
      })
      let af = localStorage.getItem('activeFolder')
      if (af && af !== 'undefined' && data.folderState.byId[af]) {
        const path = buildPath(af, data.folderState.byId).concat(af)
        yield put({
          type: appActions.ADD_OPEN_ENDPOINTKEY,
          id: getEndpointOfNode(
            data.folderState.byId[af],
            data.folderState.byId,
            data.endpointState.endpoints
          ).id,
        })
        for (let node of path) {
          yield put({ type: appActions.ADD_OPEN_FOLDERKEY, id: node })
        }
      } else {
        af = null
      }

      yield all([
        put({
          type: folderActions.LOAD_FOLDER_STATE,
          payload: data.folderState,
          activeFolder: af,
        }),
        put({
          type: assetActions.LOAD_ASSET_STATE,
          payload: data.assetState,
        }),
        put({
          type: endpointActions.LOAD_ENDPOINT_STATE,
          payload: data.endpointState,
          userLevel: user.level,
        }),
        put({
          type: appActions.SET_MAINTENANCE_MODE,
          maintenance: data.maintenance,
        }),
      ])

      if (af) yield put({ type: appActions.APPLY_LIBRARY_SORT })

      yield all([
        call(permissionsActions.getAllPermissionsSaga),
        put(settingsActions.refreshSettings()),
      ])

      yield put({
        type: LOGIN_USER_SUCCESS,
        user: user.email,
      })

      // Terms and Conditions check/prompt
      const acceptedTerms = yield call(
        [localStorage, localStorage.getItem],
        'acceptedTerms'
      )
      if (!acceptedTerms || JSON.parse(acceptedTerms) === false) {
        yield put(
          modalActions.setMessage({
            msg: (
              <p>
                By using this system you affirm that you have read and do
                consent to the BAM!{' '}
                <ExternalLink
                  href={
                    (config.links && config.links.terms) ||
                    'https://bamsales.io/terms-of-use/'
                  }
                >
                  Terms of Use
                </ExternalLink>
                .
              </p>
            ),
            title: 'Terms of Use',
          })
        )
        yield put(
          modalActions.openModal('ConfirmModal', {
            onCancel: (props) => () => {
              props.userActions.logout(user.email)
            },
            onOk: (props) => () => {
              localStorage.setItem('acceptedTerms', JSON.stringify(true))
              props.modalActions.closeModal()
            },
          })
        )
      }
    }
  } catch (error) {
    console.log('login user error', error)
    // In this case, we redirect user to the registration pending page
    if (error.code === 'UserNotConfirmedException') {
      yield put({
        type: SET_REGISTRATION_PENDING,
        status: true,
        user: action.creds.Username,
      })
    } else {
      // If the message is related to the username or password, pass it on
      // otherwise provide generic error
      let msg
      if (
        error.code === 'UserNotFoundException' ||
        error.code === 'InvalidPasswordException' ||
        error.code === 'NotAuthorizedException'
      )
        msg = error.message
      else {
        msg = 'An error occurred.  Please try again later'
      }
      yield put({
        type: LOGIN_USER_FAILURE,
        message: msg,
      })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export const clearLoginError = () => ({
  type: CLEAR_LOGIN_ERROR,
})

export const setLoginError = (message) => ({
  type: SET_LOGIN_ERROR,
  message,
})

/*
 * Public facing action creator for register saga
 */
export const registerUser = (user) => ({
  type: REGISTER,
  user,
})

/*
 * Watcher function to initiate the registerUserSaga
 */
export function* watchRegisterUser() {
  yield takeLatest(REGISTER, registerUserSaga)
}

/*
 * registerUserSaga - call the AWS Cognito service to register a new user
 *   PUT REQUEST action for saga to dispatch to tell client the async operation is in progress
 *   CALL Auth API to attempt registration
 *   PUT SUCCESS action for saga to dispatch if signup was successful. (save username to local
 *     storage and set registrationPending flag)
 *   CALL api/users/create - to save the registration inputs.  This also associates the user with
 *     a client based on the invitation ID.
 *   PUT FAILURE action for saga to dispatch to display the error message on client
 */
export function* registerUserSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })

  try {
    const config = yield select((state) => state.config.appConfig)

    yield call([Auth, Auth.signUp], {
      username: action.user.email,
      password: action.user.password,
      attributes: {
        name: action.user.name,
      },
      validationData: {
        invitationCode: action.user.invitation,
      },
      clientMetadata: {
        invitationCode: action.user.invitation,
      },
    })

    // Success! Increment the invitation code registration counter
    yield call(apiActions.fetchSaga, {
      url: `${config.baseUrl}/api/invitations/use/${action.user.invitation}`,
      init: {
        method: 'GET',
      },
    })

    // Registration worked - add user to dynamoDB
    const newUser = yield call(apiActions.fetchSaga, {
      url: `${config.baseUrl}/api/users/create`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          name: action.user.name,
          email: action.user.email,
          invitation: action.user.invitation,
          profileUrl: `${config.baseUrl}/defaults/defaultProfile.png`,
          level: 'User',
          preferences: {
            homeViewMode: 'grid',
          },
        }),
      },
      s3Put: false,
    })
    yield put({
      type: REGISTER_USER_SUCCESS,
      user: newUser,
    })
  } catch (error) {
    // Check for invitation code error
    if (error.message.startsWith('PreSignUp failed with error ')) {
      error.message = error.message.replace('PreSignUp failed with error ', '')
    }
    yield put({
      type: REGISTER_USER_FAILURE,
      error,
    })
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 * Public facing action creator for the resendConfirmEmail saga
 */
export function resendConfirmEmail(user) {
  return {
    type: RESEND_CONFIRM,
    user,
  }
}
/*
 * Watcher for the resendConfirmEmail saga
 */
export function* watchResendConfirmEmail() {
  yield takeLatest(RESEND_CONFIRM, resendConfirmEmailSaga)
}

/*
 * resendConfirmEmail - if user does not have confirmation email, they can request a new one
 *   from the registration pending page.
 * dispatches REQUEST to tell the client async operation is in progress.
 * dispatches FAILURE if AWS service returns an error (error message is displayed on the
 * registration-pending page)
 * dispatches SUCCESS if AWS service succeeds.  Displays success message to user.
 */
export function* resendConfirmEmailSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const result = yield call([Auth, Auth.resendSignUp], action.user)
    yield put({
      type: RESEND_CONFIRM_SUCCESS,
      result,
    })
  } catch (error) {
    yield put({
      type: RESEND_CONFIRM_FAILURE,
      error,
    })
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 *  Public facing action creator for fogotPassword saga
 */
export function forgotPassword(user) {
  return {
    type: FORGOT_PASSWORD,
    user,
  }
}

export function clearForgotPasswordMessage() {
  return {
    type: CLEAR_FORGOT_PASSWORD_MESSAGE,
  }
}
/*
 * Watcher function for the forgotPassword saga=
 */
export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordSaga)
}

/*
 * forgotPassword - if user forgets password, they can initiate a reset.  This function
 *  sends them a code that must be included with the password change.
 *  PUTs REQUEST to tell the client async operation is in progress.
 *  PUTs FAILURE if email is missing or if AWS is unable to send the code
 *  PUTs SUCCESS if AWS sends the code to the email
 */
export function* forgotPasswordSaga(action) {
  yield put({ type: FORGOT_PASSWORD_REQUEST })
  try {
    // Require an email address
    if (action.user === '') {
      throw new Error({ message: 'You must enter an email address' })
    }
    // Try to send the forgot passowrd email
    const result = yield call([Auth, Auth.forgotPassword], action.user)
    yield put({ type: FORGOT_PASSWORD_SUCCESS, result })
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_FAILURE, error })
  }
}

/*
 * Public facing action creator for resetPassword
 */
export function resetPassword(user, code, pw) {
  return {
    type: RESET_PASSWORD,
    user,
    code,
    pw,
  }
}

/*
 * Watcher for reset password saga
 */
export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga)
}

/*
 * resetPassord - using reset code from AWS Cognito, change a user's password
 *  PUTs REQUEST to tell client async action is in progress
 *  CALLs Auth API to reset password
 *  PUTs SUCCESS if reset password was successful - redirect to success page
 *  PUTs FAILURE if reset password failed  display error message to user.
 */
export function* resetPasswordSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const result = yield call(
      [Auth, Auth.forgotPasswordSubmit],
      action.user,
      action.code,
      action.pw
    )
    yield put({ type: RESET_PASSWORD_SUCCESS, result })
  } catch (error) {
    yield put({ type: RESET_PASSWORD_FAILURE, error })
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 * Public facing action creator for the restore session saga
 */
export function restoreSession(user) {
  return {
    type: RESTORE_SESSION,
    user,
  }
}

/*
 * Watcher function for the restore session saga
 */
export function* watchRestoreSession() {
  yield takeLatest(RESTORE_SESSION, restoreSessionSaga)
}

/*
 * restoreSession - attempt to restore cognito session from local storage
 */
export function* restoreSessionSaga(action) {
  try {
    const config = yield select((state) => state.config.appConfig)
    const result = yield call([Auth, Auth.currentAuthenticatedUser])
    LogRocket.identify((result && result.username) || action.user, {
      stage: config.stage,
    })

    const user = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/${encodeURI(action.user)}`,
      init: { method: 'GET' },
    })
    const version = yield select((state) => state.appState.version)

    yield call(updateAnalyticsEndpoint, user, version, config)
    yield put({ type: GET_USER_DATA_SUCCESS, user })

    const data = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/zipstate/current`,
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
      },
      init: {
        method: 'POST',
        body: JSON.stringify(user.email),
      },
    })

    yield all([
      put({
        type: folderActions.LOAD_FOLDER_STATE,
        payload: data.folderState,
        activeFolder: '',
      }),
      put({
        type: assetActions.LOAD_ASSET_STATE,
        payload: data.assetState,
      }),
      put({
        type: endpointActions.LOAD_ENDPOINT_STATE,
        payload: data.endpointState,
        userLevel: user.level,
      }),
      put({
        type: appActions.SET_MAINTENANCE_MODE,
        maintenance: data.maintenance,
      }),
    ])

    yield all([
      call(permissionsActions.getAllPermissionsSaga),
      put(settingsActions.refreshSettings()),
    ])

    yield put({
      type: LOGIN_USER_SUCCESS,
      user: action.user,
      result,
    })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    if (!/not authenticated/.test(error)) {
      console.error('restore session saga error', error)
    }

    yield put({ type: LOGOUT_SUCCESS, force: true })
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 * Public facing action creator for the logout saga
 */
export function logout(user) {
  return {
    type: LOGOUT,
    user,
  }
}
/*
 * Watcher function for the logout saga
 */
export function* watchLogout() {
  yield takeLatest(LOGOUT, logoutSaga)
}

/*
 * logout - request logout from Cognito service.  Remove any cached tokens
 */
export function* logoutSaga(action) {
  yield put({ type: LOGOUT_REQUEST })
  try {
    yield call([Auth, Auth.signOut], action.user)
    yield put({ type: LOGOUT_SUCCESS })
    yield call(updateAnalyticsEndpoint)
  } catch (error) {
    yield put({ type: LOGOUT_FAILURE, error })
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 *  Public facing action creator for the change password saga
 */
export function changePassword(user, oldPw, newPw) {
  return {
    type: CHANGE_PASSWORD,
    user,
    oldPw,
    newPw,
  }
}

/*
 * watcher function for the change password saga
 */
export function* watchChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga)
}

/*
 * changePassword - dispatch an action to change a user's password
 *
 */
export function* changePasswordSaga(action) {
  yield put({ type: CHANGE_PASSWORD_REQUEST })

  try {
    let user

    try {
      user = yield call([Auth, Auth.currentAuthenticatedUser])
    } catch (error) {
      const result = yield call(sessionTimeoutSaga, {})
      if (!result) throw new SessionTimeoutError()
    }

    yield call([Auth, Auth.changePassword], user, action.oldPw, action.newPw)

    yield all([
      put({ type: CHANGE_PASSWORD_SUCCESS, msg: 'Password Changed' }),
      put(
        modalActions.setMessage({
          type: 'SUCCESS',
          msg: 'Your password has been successfully changed.',
        })
      ),
      put(modalActions.openModal('MessageModal')),
    ])
  } catch (error) {
    yield all([
      put({ type: CHANGE_PASSWORD_FAILURE, msg: error.message }),
      put(modalActions.setMessage({ type: 'ERROR', msg: error.message })),
      put(modalActions.openModal('MessageModal')),
    ])
  }
}

/*
 *  getUserData - public facing action creator for the getUserData saga
 */
export function getUserData(email) {
  return {
    type: GET_USER_DATA,
    email,
  }
}

/*
 * watchGetUserData - saga initiator function for getUserData
 */
export function* watchGetUserData() {
  yield takeLatest(GET_USER_DATA, getUserDataSaga)
}

/*
 * getUserDataSaga - fetch the user data from DB based on the email
 */
export function* getUserDataSaga(action) {
  yield put({ type: GET_USER_DATA_REQUEST })
  try {
    const config = yield select((state) => state.config.appConfig)
    const u = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/${encodeURI(action.email)}`,
      init: { method: 'GET' },
    })
    yield put({ type: GET_USER_DATA_SUCCESS, u })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: LOGOUT })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: { type: 'ERROR', msg: error.clientMessage || 'Get User Failed' },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: GET_USER_DATA_FAILURE })
    }
  }
}

/*
 * updateUserProfile - public facing action creator`
 */
export function updateUserProfile(email, updates) {
  return {
    type: UPDATE_USER_PROFILE,
    email,
    updates,
  }
}

/*
 * watch updateUserProfile - watcher function to initiate the saga
 */
export function* watchUpdateUserProfile() {
  yield takeLatest(UPDATE_USER_PROFILE, updateUserProfileSaga)
}

/*
 *
 */
export function* updateUserProfileSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/update`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          email: action.email,
          updates: action.updates,
        }),
      },
    })
    yield put({
      type: UPDATE_USER_PROFILE_SUCCESS,
      email: action.email,
      updates: action.updates,
    })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    yield put({ type: appActions.COMPLETE_ASYNC })
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: LOGOUT })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Update User Failed',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: UPDATE_USER_PROFILE_FAILURE })
    }
  }
}

export const sessionTimeout = (user, password, quit, callback) => ({
  type: SESSION_TIMEOUT_ACTION,
  user,
  password,
  quit,
  callback,
})

export function* watchSessionTimeout() {
  yield takeEvery(SESSION_TIMEOUT_ACTION, sessionTimeoutSaga)
}

export function* sessionTimeoutSaga(action) {
  yield put({ type: LOGOUT })
  return

  let success = false
  yield put({ type: appActions.COMPLETE_ASYNC })
  yield put({ type: modalActions.OPEN_MODAL, modal: 'SessionTimeoutModal' })
  while (true) {
    const result = yield take(SESSION_TIMEOUT_ACTION)
    if (result.quit) {
      break
    } else {
      const code = yield call(loginUserSaga, {
        creds: {
          Username: result.user,
          Password: result.password,
        },
        //refresh: true,
      })
      //if no errors, return
      if (!code) {
        yield put({ type: CLEAR_LOGIN_ERROR })
        success = true
        break
      }
    }
  }
  yield put({ type: modalActions.CLOSE_MODAL })
  // Call callback if it was passed - BamAppSyncClient will
  // pass this to reconnect after a session timeout
  if (action.callback && typeof action.callback === 'function')
    yield call(action.callback)

  return success
}

export const setProfilePreview = (url) => ({
  type: SET_PROFILE_PREVIEW,
  url,
})

export const adminCreateUser = (
  name,
  email,
  confirmType,
  password,
  invitation
) => ({
  type: ADMIN_CREATE_USER,
  name,
  email,
  confirmType,
  password,
  invitation,
})

export function* watchAdminCreateUser() {
  yield takeLatest(ADMIN_CREATE_USER, adminCreateUserSaga)
}

export function* adminCreateUserSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    const config = yield select((state) => state.config.appConfig)
    const result = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/adminCreate/`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          name: action.name,
          email: action.email.toLowerCase(),
          confirmType: action.confirmType,
          password: action.password,
          invitation: action.invitation,
          origin: window.location.hostname,
        }),
      },
    })

    yield put({
      type: ADMIN_CREATE_USER_SUCCESS,
      newUser: result.user,
      groups: result.groups,
    })
    yield put(modalActions.closeModal())
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: LOGOUT })
    } else {
      yield put({
        type: ADMIN_CREATE_USER_ERROR,
        error: (error.result && error.result.message) || error.message,
      })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export const importUsers = (usersData) => ({
  type: IMPORT_USERS,
  usersData,
})

export function* watchImportUsers() {
  yield takeLatest(IMPORT_USERS, importUsersSaga)
}

function* fetchGroup(groupName) {
  // find a group
  const existingGroups = yield select((state) => state.permissions.groups)

  const group = Object.values(existingGroups).find(
    ({ name }) => name === groupName
  )
  // or
  if (group) {
    console.log(`existing group: ${groupName}`)
    return group
  }

  // create a group
  const config = yield select((state) => state.config.appConfig)
  const id = md5(`${groupName}${Date.now()}`)
  const groupData = {
    id,
    name: groupName,
    description: groupName,
    members: [],
    system: false,
  }
  const result = yield call(apiActions.secureFetchSaga, {
    url: `${config.baseUrl}/api/permissions/groups/add`,
    init: {
      method: 'POST',
      body: JSON.stringify(groupData),
    },
  })

  console.log({ result }, `created group: ${groupName}`)
  yield put({ type: permissionsActions.ADD_GROUP_SUCCESS, group: groupData })

  return groupData
}

function* fetchInviteForGroups(groupsList, inviteMap) {
  // fetch the invite for the set of groups

  // check the invite map-
  let inviteCode = inviteMap[groupsList]
  if (inviteCode) return inviteCode

  // check existing invites
  const groupNames = groupsList.split(';')
  const existingGroups = yield select((state) => state.permissions.groups)
  const existingInvites = yield select((state) => state.invitations.invitations)

  const existingInvite = existingInvites.find((invite) => {
    // if the invite doesn't include as many groups as are in our list, it isn't the invite we need
    if (invite.groups.length < groupNames.length) return false

    // we are looking for an invite with the same set of groups (leaving out system groups)
    const existingInviteGroupNames = invite.groups
      .filter((id) => !id.startsWith('SYS'))
      .map((id) => existingGroups[id]?.name)

    // after removing system groups, a matching existing invite would have the same number of groups
    if (existingInviteGroupNames.length !== groupNames.length) return false

    // check to see if every group we are looking for is included in the existing invite
    return groupNames.every((groupName) =>
      existingInviteGroupNames.includes(groupName)
    )
  })
  if (existingInvite) {
    console.log(`existing invite found: ${existingInvite.id}`)

    // store the invite in the invite map
    inviteMap[groupsList] = existingInvite.id

    return existingInvite.id
  } else {
    const sameNameInvite = existingInvites.find(
      (invite) => invite.name === groupsList
    )
    if (sameNameInvite) {
      console.log({ sameNameInvite, groupsList, existingGroups })
      throw new Error(`invite with same name exists: ${groupsList}`)
    }
  }

  // make sure each group in the set exists
  const groupIds = []
  for (const groupName of groupNames) {
    const group = yield fetchGroup(groupName)
    groupIds.push(group.id)
  }

  // create an invite for the set of groups
  const config = yield select((state) => state.config.appConfig)
  const userClient = yield select((state) => state.user.client)
  const nanoid = customAlphabet(
    '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    7
  )
  const invitationCode = nanoid()
  const invitationData = {
    name: groupsList,
    id: invitationCode,
    groups: groupIds,
    valid: true,
    uses: 100,
    registrations: 0,
    client: userClient,
    deleted: false,
    created: Date.now(),
  }

  const result = yield call(apiActions.secureFetchSaga, {
    url: `${config.baseUrl}/api/invitations/add`,
    init: {
      method: 'POST',
      body: JSON.stringify(invitationData),
    },
  })
  console.log({ result }, `created invite: ${invitationCode}`)

  // store the invite in the invite map
  inviteMap[groupsList] = invitationCode

  // push invite into redux state
  yield put({
    type: invitationActions.ADD_INVITATION_SUCCESS,
    invitation: invitationData,
  })

  return invitationCode
}

function* addUserWithGroups(userData, inviteMap) {
  try {
    // look for existing user
    const config = yield select((state) => state.config.appConfig)

    // get or create an invitation
    const inviteCode = yield userData.invite ||
      (userData.groups && fetchInviteForGroups(userData.groups, inviteMap))

    if (!inviteCode) throw new Error('no invitation for user')

    // create the user with the invite
    const result = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/adminCreate/`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          name: userData.name || `${userData.first} ${userData.last}`,
          email: userData.email.toLowerCase(),
          confirmType: 'auto',
          password: userData.password,
          invitation: inviteCode,
        }),
      },
    })

    yield put({
      type: ADMIN_CREATE_USER_SUCCESS,
      newUser: result.user,
      groups: result.groups,
    })

    console.log({ result }, `created user: ${userData.email}`)
  } catch (error) {
    console.log('error', error)
    console.log(
      { userData, inviteMap },
      `addUserWithGroups failed: ${userData.email}`
    )
  }
}

export function* importUsersSaga({ usersData }) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

    const inviteMap = {}

    for (let i = 0; i < usersData.length; ++i) {
      const user = usersData[i]
      console.log(
        { user },
        `*** adding user ${i + 1} of ${usersData.length}: ${user.email} ***`
      )
      yield addUserWithGroups(user, inviteMap)
    }
  } catch (error) {
    console.log({ error }, 'importUserSaga error')
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export default all([
  watchImportUsers(),
  watchLoginUser(),
  watchRegisterUser(),
  watchResendConfirmEmail(),
  watchForgotPassword(),
  watchResetPassword(),
  watchRestoreSession(),
  watchLogout(),
  watchChangePassword(),
  watchGetUserData(),
  watchUpdateUserProfile(),
  watchAdminCreateUser(),
  watchSessionTimeout(),
])
